  window.onload = function () {


      var allContent = document.getElementsByClassName('set-content')
      if (allContent.length != 0) {

        for (var i = 0; i < allContent.length; i++) {
          allContent[i].style.display = 'none'
        }
        allContent[0].style.display = 'block'
      }



      var newlist = document.getElementsByClassName('grid-product__content')
      for (var i = 0; i < newlist.length; i++) {
        if (newlist[i].getElementsByClassName('grid-product__tag grid-product__tag--sale')[0] != undefined) {
          newlist[i].getElementsByClassName('grid-product__tag grid-product__tag--sale')[0].innerHTML = newlist[i].getElementsByTagName('a')[0].getElementsByClassName('grid-product__price')[0].getElementsByClassName('grid-product__price--savings')[0].innerHTML

        }
        newlist[i].getElementsByTagName('a')[0].getElementsByClassName('grid-product__price')[0].getElementsByClassName('grid-product__price--savings')[0].hidden = 'hidden'

      }

    }

    function toggleStatus(obj) {
      var allContent = document.getElementsByClassName('set-content')
      for (var i = 0; i < allContent.length; i++) {
        if (allContent[i].getAttribute('data-toggle') == obj.getAttribute("data-toggle")) {
          if (allContent[i].style.display == 'block') {
            allContent[i].style.display = 'none'
            obj.childNodes[1].style.display = 'block';
            obj.childNodes[3].style.display = 'none';
          } else {
            allContent[i].style.display = 'block'
            obj.childNodes[1].style.display = 'none';
            obj.childNodes[3].style.display = 'block';
          }
        }
      }
    }